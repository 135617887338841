.Container {
  display: flex;

  .Main {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    width: 100%;
    margin-top: 24px;
  }

  .Users {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    width: 100%;
    margin-top: 24px;
  }

  .Promotions {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    width: 100%;
    margin-top: 24px;
  }
}
